import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { Button, Chip, makeStyles, SvgIconTypeMap, withStyles } from "@material-ui/core";
import axios from "axios";
import { useRecoilState } from "recoil";
import { settings, getHeaders } from "../../settings";
import ContentWrapper from "../../components/ContentWrapper";
import MainHeader from "../../components/MainHeader";
import MainTable from "../../components/MainTable";
import TitleHeader from "../../components/TitleHeader";
import DividerWrapper from "../../components/DividerWrapper";
// import EditButton from "../../components/Buttons/EditButton";
import ReproveButton from "../../components/Buttons/ReproveButton";
import ApproveButton from "../../components/Buttons/ApproveButton";
import FinalizeButton from "../../components/Buttons/FinalizeButton";

import AcquiringAdd from "./AcquiringAdd";
import AcquiringEdit from "./AcquiringEdit";
import { Link } from "react-router-dom";

import { Grid, Typography } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

import {
  confirmationDialogState,
  dialogState,
  loadingOverlayState,
  snackBarState,
  handleErrorState,
} from "../../GlobalAtoms";
import EditButton from "../../components/Buttons/EditButton";
import { red, green, teal, blue } from "@material-ui/core/colors";
import { GetApp, Visibility } from "@material-ui/icons";

const useStyles = makeStyles({
  openChip: {
    background: green[600],
    color: '#fff',
  },
  approvedChip: {
    background: blue[700],
    color: '#fff',
  },
  finalizededChip: {
    background: teal[700],
    color: '#fff',
  },
  cancelChip: {
    background: red[700],
    color: '#fff',
  },
});

const ConfirmButton = withStyles(() => ({
  root: {
    color: "#fff",
    backgroundColor: green[600],
    "&:hover": {
      backgroundColor: green[700],
    },
    minWidth: "0 !important",
    padding: "8px 10px !important",
  },
}))(Button);

const ViewButton = withStyles(() => ({
  root: {
    color: "#fff",
    backgroundColor: teal[600],
    "&:hover": {
      backgroundColor: teal[700],
    },
    minWidth: "0 !important",
    padding: "8px 10px !important",
  },
}))(Button);



const Acquiring: React.FC<{
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}> = () => {
  const classes = useStyles();

  // States
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  const [company, setCompany] = useState<any>({stores: []});
  const [previous, setPrevious] = useState("");
  const [current, setCurrent] = useState("");
  const [next, setNext] = useState("");
  const { monthParam, yearParam } = useParams<any>();

  // Recoil
  const [dialog, setDialogState] = useRecoilState(dialogState);
  const [confirmationDialog, setConfirmationDialogState] = useRecoilState(
    confirmationDialogState
  );
  // eslint-disable-next-line no-unused-vars
  const [handleError, setHandleError] = useRecoilState(handleErrorState);
  // eslint-disable-next-line no-unused-vars
  const [loadingOverlay, setLoadingOverlayState] =
    useRecoilState(loadingOverlayState);
  // eslint-disable-next-line no-unused-vars
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);

  useEffect(() => {
    let month: any = "";
    let year: any = "";
    if (monthParam && yearParam) {
        setLoadingOverlayState(true);
        month = monthParam;
        year = yearParam;
    } else {
        const date = new Date();
        month = date.getMonth() + 1;
        year = date.getFullYear();
    }

    const url = `${settings().apiUrl}/api/orders/acquiring/${month}/${year}`;
    const headers = getHeaders();

    axios
      .get(url, { headers })
      .then((response) => {
        setData([...response.data.acquiringData]);
        setLoading(false);
        setPrevious(response.data.previous);
        setCurrent(response.data.current);
        setNext(response.data.next);
        setLoadingOverlayState(false);

      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );

  }, [monthParam, yearParam]);

  const openEditDialog = (rowData: {
    [key: string]: any
  }, indexKey: number) => {
    setLoadingOverlayState(true);

    const url = `${settings().apiUrl}/api/orders/${rowData.id}`;
    const headers = getHeaders();

    axios
      .get(url, { headers })
      .then((response) => {
        setLoadingOverlayState(false);
        setDialogState({
          ...dialog,
          open: true,
          title: 'Visualizar Pedido',
          content: <AcquiringEdit
            editKey={indexKey}
            editDialogData={{...response.data.data}}
            setData={setData}
            data={data}
          />,
          maxWidth: "xl",
          transition: "regular",
        })
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );


  };

  const exportOrder = (rowData: {
    [key: string]: any
  }, indexKey: number) => {
    setLoadingOverlayState(true);

    const url = `${settings().apiUrl}/api/orders/export/${rowData.id}`;
    const headers = getHeaders();

    axios
      .get(url, { headers })
      .then((response) => {
        setLoadingOverlayState(false);
        window.open( `${settings().cdnUrl}/tmp/${response.data}`);
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );
  };

  const deleteItem = async (rowData: {
    [key: string]: any
  }) => {
    setLoadingOverlayState(true);

    let url = `${settings().apiUrl}/api/orders/status/cancel/${rowData.id}`;
    let headers = getHeaders();

    const body = JSON.stringify({
      status: 3,
    });

    await axios
      .put(url, body, { headers })
      .then((response) => {
        setSnackBar({
          open: true,
          type: "success",
          message: 'Pedido cancelado com sucesso.',
        });
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );

    url = `${settings().apiUrl}/api/orders/acquiring`;
    headers = getHeaders();

    axios
      .get(url, { headers })
      .then((response) => {
        setData([...response.data.data]);
        setLoadingOverlayState(false);
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );
  };

  const approveItem = async (rowData: {
    [key: string]: any
  }) => {
    setLoadingOverlayState(true);

    let url = `${settings().apiUrl}/api/orders/status/approve/${rowData.id}`;
    let headers = getHeaders();

    const body = JSON.stringify({
      status: 1,
    });

    await axios
      .put(url, body, { headers })
      .then((response) => {
        setSnackBar({
          open: true,
          type: "success",
          message: 'Pedido aprovado com sucesso.',
        });
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );

    url = `${settings().apiUrl}/api/orders/acquiring`;
    headers = getHeaders();

    axios
      .get(url, { headers })
      .then((response) => {
        setData([...response.data.data]);
        setLoadingOverlayState(false);
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );
  };

  const finalizeItem = async (rowData: {
    [key: string]: any
  }) => {
    setLoadingOverlayState(true);

    let url = `${settings().apiUrl}/api/orders/status/finalize/${rowData.id}`;
    let headers = getHeaders();

    const body = JSON.stringify({
      status: 2,
    });

    await axios
      .put(url, body, { headers })
      .then((response) => {
        setSnackBar({
          open: true,
          type: "success",
          message: 'Pedido finalizado com sucesso.',
        });
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );

    url = `${settings().apiUrl}/api/orders/acquiring`;
    headers = getHeaders();

    axios
      .get(url, { headers })
      .then((response) => {
        setData([...response.data.data]);
        setLoadingOverlayState(false);
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );
  };

  const addDialog = <AcquiringAdd setData={setData} data={data} company={company} />;

  const columns = [
    {
      label: 'N.º',
      name: "id",
    },
    {
      label: "Sigla",
      name: "store_initials",
    },
    {
      label: 'Data',
      name: "created_at",
    },
    {
      label: 'Data Pretendida',
      name: "preferred_date",
    },
    {
      label: 'Usuário',
      name: "customer_name",
    },
    {
      label: 'Solicitado',
      name: "requested_value",
    },
    {
      label: 'Entrega',
      name: "coin_delivery_fee_paid",
    },
    {
      label: 'Status',
      name: "status",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: number,
          tableMeta: any,
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <>
            {value == 0 &&  <Chip label="Aberto" className={classes.openChip} /> }
            {value == 1 &&  <Chip label="Aprovado" className={classes.approvedChip} /> }
            {value == 2 &&  <Chip label="Finalizado" className={classes.finalizededChip} /> }
            {value == 3 &&  <Chip label="Cancelado" className={classes.cancelChip} /> }
          </>
        ),
      },
    },
    {
      label: 'Entrega',
      name: "confirmed_date",
    },
    {
      label: ' ',
      name: "status",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: number,
          tableMeta: any,
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <>
            {data[tableMeta.currentTableData[tableMeta.rowIndex].index].status !== '3' &&
              <ReproveButton
                onClick={() =>
                  setConfirmationDialogState({
                    ...confirmationDialog,
                    open: true,
                    cancelText: 'fechar',
                    confirmationText: 'cancelar pedido',
                    question: `Você tem certeza que deseja cancelar o pedido N.º ${
                      data[tableMeta.currentTableData[tableMeta.rowIndex].index].id
                    }?`,
                    action: () => deleteItem(data[tableMeta.currentTableData[tableMeta.rowIndex].index]),
                    transition: "default",
                  })
                }
              />
            }
          </>
        ),
      },
    },
    {
      label: ' ',
      name: "status",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: number,
          tableMeta: any,
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <>
            {data[tableMeta.currentTableData[tableMeta.rowIndex].index].status === '0' &&
              <ApproveButton
                onClick={() =>
                  setConfirmationDialogState({
                    ...confirmationDialog,
                    open: true,
                    question: `Você tem certeza que deseja aprovar o pedido N.º ${
                      data[tableMeta.currentTableData[tableMeta.rowIndex].index].id
                    }?`,
                    action: () => approveItem(data[tableMeta.currentTableData[tableMeta.rowIndex].index]),
                    transition: "default",
                  })
                }
              />
            }
            {data[tableMeta.currentTableData[tableMeta.rowIndex].index].status === '1' &&
              <FinalizeButton
                onClick={() =>
                  setConfirmationDialogState({
                    ...confirmationDialog,
                    open: true,
                    cancelText: 'fechar',
                    confirmationText: 'finalizar pedido',
                    question: `Você tem certeza que deseja finalizar o pedido N.º ${
                      data[tableMeta.currentTableData[tableMeta.rowIndex].index].id
                    }?`,
                    action: () => finalizeItem(data[tableMeta.currentTableData[tableMeta.rowIndex].index]),
                    transition: "default",
                  })
                }
              />
            }
          </>
        ),
      },
    },
    {
      label: ' ',
      name: "id",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: number,
          tableMeta: any,
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <ViewButton
            onClick={() =>
              openEditDialog(data[tableMeta.currentTableData[tableMeta.rowIndex].index], tableMeta.currentTableData[tableMeta.rowIndex].index)
            }
            variant="contained" size="small"
          >
            <Visibility style={{ fontSize: 18 }} />
          </ViewButton>
        ),
      },
    },
    {
      label: ' ',
      name: "id",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: number,
          tableMeta: any,
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <ConfirmButton
            onClick={() =>
              exportOrder(data[tableMeta.currentTableData[tableMeta.rowIndex].index], tableMeta.currentTableData[tableMeta.rowIndex].index)
            }
            variant="contained" size="small"
          >
            <GetApp style={{ fontSize: 18 }} />
          </ConfirmButton>
        ),
      },
    },
  ];

  return (
    <>
      <ContentWrapper loading={loading}>
        <MainHeader
          title={<TitleHeader>{'Recolhimento de Cédula'}</TitleHeader>}
          button={<></>}
        />
                        <DividerWrapper />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} style={{ textAlign: "left" }}>
                        <Button
                            component={Link}
                            to={`/dashboard/acquring/${previous}`}
                            variant="contained"
                        >
                            <ChevronLeft /> {previous}
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
                        <Typography variant="h5">{current}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} style={{ textAlign: "right" }}>
                        <Button
                            component={Link}
                            to={`/dashboard/acquring/${next}`}
                            variant="contained"
                        >
                            {next} <ChevronRight />
                        </Button>
                    </Grid>
                </Grid>

        <DividerWrapper />
        <MainTable data={data} columns={columns} />
      </ContentWrapper>
    </>
  );
};

export default Acquiring;
